import logo from './logo.svg';
import './App.css';
import { Topmenu } from './components/header/Topmenu'
import { Profile } from './components/profile/Profile'

import { FaXmark, FaBars } from "react-icons/fa6";
import { useState,useEffect } from 'react';
import { Mobilemenu } from './components/header/Mobilemenu';

import { Aboutme } from './components/about/Aboutme'
import { Projects } from './components/projects/Projects';
import { Contact } from './components/contact/Contact';
import { Footer } from './components/footer/Footer'




function App() {

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://aboutme.momehmandoost.ir');
    }, 1);

    return () => clearTimeout(timeout);
  }, []);

  const [click, setClick] = useState(false);

  return (
    <>
      <nav className='header ' >
        <div className='logo'>
          <h3>MOAMD DEV</h3>
        </div>
        <Topmenu />
        <Mobilemenu />
      </nav>


      <section id="home">

        <div className='container'>
          <div className='content'>
            <div className="hero-main">
              <Profile />
            </div>
          </div>
        </div>

      </section>

      <section id="about">
        <div className='container'>

          <Aboutme />

        </div>

      </section>



      <section id="projects" class="project">
        <div className='container'>

          <Projects />

        </div>
      </section>



      <section id="contact" class="contact">
        <div className='container'>

          <Contact />

        </div>
      </section>


      <footer>
        <div class="container">
          <Footer />
        </div>
      </footer>



    </>

  );
}

export default App;
