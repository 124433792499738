import React from "react";
import projectspic from "../../assets/Projects1.webp";
import projectspic2 from "../../assets/Projects2.png";
import projectspic3 from "../../assets/Projects3.png";
import projectspic4 from "../../assets/Projects4.png";
import "./projects.css";
import { FaGithub, FaLink } from "react-icons/fa6";

export const Projects = () => {
  return (
    <div class="project-content">
      <p>Portfolio</p>
      <h3>Here are my projects⛳</h3>
      <div class="projects-grid">
        <div class="pro pro__1 undefined">
          <div class="pro__img">
            <a
              target="_blank"
              href="https://coinmark.vercel.app/"
              rel="noreferrer"
            >
              <img src={projectspic2} alt="website" className="ppicct" />
            </a>
          </div>
          <div class="pro__text">
            <h3>CoinMark💰</h3>
            <p>
              efficient way to explore the vast world of cryptocurrencies. Our
              platform is designed to empower both crypto enthusiasts and
              newcomers alike, providing a user-friendly and intuitive interface
              to access essential information about various cryptocurrencies.
            </p>
            <div class="stack">
              <p>React JS</p>
              <p>RestAPI</p>
              {/* <p>Vanilla CSS</p> */}
            </div>
            <div class="links">
              <a
                target="_blank"
                href="https://github.com/momehmandoost/coinmark"
                rel="noreferrer"
              >
                Code
                <FaGithub />
              </a>
              <a
                target="_blank"
                href="https://coinmark.vercel.app/"
                rel="noreferrer"
              >
                Live Demo
                <FaLink />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="projects-grid">
        <div class="pro pro__1 undefined">
          <div class="pro__img">
            <a
              target="_blank"
              href="https://github.com/momehmandoost/hypebeast"
              rel="noreferrer"
            >
              <img src={projectspic3} alt="website" className="ppicct" />
            </a>
          </div>
          <div class="pro__text">
            <h3>HypeBeast👔</h3>
            <p>
              a modern web application for a stylish online shopping experience,
              with a focus on streetwear and trendy fashion.
            </p>
            <div class="stack">
              <p>React JS</p>
              <p>Strapi</p>
              {/* <p>Sass</p>
              <p>Redux</p> */}
            </div>
            <div class="links">
              <a
                target="_blank"
                href="https://github.com/momehmandoost/hypebeast"
                rel="noreferrer"
              >
                Code
                <FaGithub />
              </a>
              <a
                target="_blank"
                href="https://github.com/momehmandoost/hypebeast"
                rel="noreferrer"
              >
                Live Demo
                <FaLink />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="projects-grid">
        <div class="pro pro__1 undefined">
          <div class="pro__img">
            <a
              target="_blank"
              href="https://github.com/momehmandoost/portfolio_3d"
              rel="noreferrer"
            >
              <img src={projectspic4} alt="website" className="ppicct" />
            </a>
          </div>
          <div class="pro__text">
            <h3>Portfolio-3D-Website🌌</h3>
            <p>
              showcases an innovative and visually captivating portfolio website
              built using cutting-edge technologies.
            </p>
            <div class="stack">
              <p>React JS</p>
              <p>ThreeJS</p>
              {/* <p>EmailJS</p> */}
            </div>
            <div class="links">
              <a
                target="_blank"
                href="https://github.com/momehmandoost/portfolio_3d"
                rel="noreferrer"
              >
                Code
                <FaGithub />
              </a>
              <a
                target="_blank"
                href="https://github.com/momehmandoost/portfolio_3d"
                rel="noreferrer"
              >
                Live Demo
                <FaLink />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
