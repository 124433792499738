import React, { useState } from "react";
import { FaBarsStaggered, FaXmark } from "react-icons/fa6";
import "./mobile.css";

export const Mobilemenu = () => {
  const [click, setClick] = useState(false);

  return (
    <>
      <div className="hamburger" onClick={() => setClick(!click)}>
        {click ? <FaXmark /> : <FaBarsStaggered />}
      </div>
      {click ? (
        
          <div className="sidemenu">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#projects">Projects</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li></li>
            </ul>
          </div>
        
      ) : null}
    </>
  );
};
