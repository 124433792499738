import React from "react";
import "./footer.css";
import { FaLinkedin, FaGithub } from "react-icons/fa6";

export const Footer = () => {
  return (
    <div>
      <div class="footerc">
        <h3>MADE WITH 🤍. ALL RIGHT RESERVED 2023 .</h3>
        <div class="footerc__socials">
          <a
            aria-label="linkedin"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/momehmandoost/"
          >
            <FaLinkedin
              style={{ color: "white" }}
              size={30}
              onMouseOver={({ target }) => (target.style.color = "#637150")}
              onMouseOut={({ target }) => (target.style.color = "white")}
            />
          </a>
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/momehmandoost"
          >
            <FaGithub
              style={{ color: "white" }}
              size={30}
              onMouseOver={({ target }) => (target.style.color = "#637150")}
              onMouseOut={({ target }) => (target.style.color = "white")}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
