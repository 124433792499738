import React from "react";
import "./menu.css";
import { FaXmark, FaBars } from "react-icons/fa6";

export const Topmenu = (props) => {

  return (
    <div>
        <div className="menu-nav">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li></li>
          </ul>
        </div>
    </div>
  );
};
