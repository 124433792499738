import React from "react";
import { FaMapLocation, FaMessage } from "react-icons/fa6";
import "./contact.css";

export const Contact = () => {
  return (
    <div class="contact__content">
      <div class="contact__title">
        <p>contact</p>
        <h3>Hit me up! </h3>
      </div>
      <div class="contact__icons">
        <div class="contact__icon-box">
          <FaMapLocation size={50} style={{ color: "#7b935b" }} />
          <div class="contact__info">
            <h3>Location</h3>
            <p>TEHRAN, IRAN</p>
          </div>
        </div>
        <div class="contact__icon-box">
          <FaMessage size={40} style={{ color: "#7b935b" }} />
          <div class="contact__info">
            <h3>Mail</h3>
            <a href="mailto:mehmandoost2@gmail.com">mehmandoost2@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};
