import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa6";
import "./profile.css";
import profilepic from "../../assets/emoji-hand.png";

export const Profile = () => {
  return (
    <>
      <div className="hero-text">
        <div className="profile-title-contain">
          <h1>Front-End Web Developer</h1>
        </div>
        <p className="aboutme-p">
          Hi, I'm Mohammad Mehmandoost . Front-end Developer based in TEHRAN,IR.
          📍
        </p>

        <div className="iconwrap">
          <a
            aria-label="linkedin"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/momehmandoost/"
          >
            <FaLinkedin
              style={{ color: "black" }}
              size={30}
              onMouseOver={({ target }) => (target.style.color = "#637150")}
              onMouseOut={({ target }) => (target.style.color = "black")}
            />
          </a>
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/momehmandoost"
          >
            <FaGithub
              style={{ color: "black" }}
              size={30}
              onMouseOver={({ target }) => (target.style.color = "#637150")}
              onMouseOut={({ target }) => (target.style.color = "black")}
            />
          </a>
        </div>

        <div className="techstacks">
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/React-Dark.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/JavaScript.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />

          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/NextJS-Dark.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/Git.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/TypeScript.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/TailwindCSS-Dark.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/Sass.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/Figma-Dark.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
          <img
            className="techstacks-hover"
            src="https://raw.githubusercontent.com/tandpfun/skill-icons/59059d9d1a2c092696dc66e00931cc1181a4ce1f/icons/Redux.svg"
            alt="Girl in a jacket"
            width="50"
            height="50"
          />
        </div>
      </div>
      <div className="hero-img"></div>
    </>
  );
};
