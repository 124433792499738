import React from "react";
import laptop from "../../assets/laptop.jpg";
import frontend from "../../assets/text2.3d5aa6ba2d0632bb4e0572631c3f9dc2.svg";
import man from "../../assets/man.png";
import "./aboutme.css";

export const Aboutme = () => {
  return (
    <div className="about-content">
      <div className="img-side">
        <img src={man} alt="emoji" className="work-emoji" />
        <img src={laptop} alt="mee" className="img-side__main-img" />
        <span>
          <img src={frontend} alt="text" />
        </span>
      </div>
      <div className="text-side">
        <h3>About me</h3>
        <h4>
          A Committed Frontend Developer 🚀 <br /> based in TEHRAN,IRAN
        </h4>
        <p>
          a frontend developer with a unique blend of expertise - from a
          Bachelor's in Electrical Engineering (GPA: 16.8) and a Master's in
          Information Technology. Armed with proficiency in ReactJS, NextJS, and
          JavaScript, complemented by skills in Sass, Styled Components, and
          Figma. Adept at leveraging Git, Python, Express, TypeScript, and
          TensorFlow to create seamless user experiences that bridge technology
          and design.
        </p>
      </div>
    </div>
  );
};
